<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455.39 108.6"><g>
    <polygon shape-rendering="geometricPrecision" class="cls-1" points="0 62.2 0 74.52 27.15 91.25 78.96 59.33 78.96 47.02 27.15 78.93 0 62.2"/>
    <polygon shape-rendering="geometricPrecision" class="cls-1" points="0 32.54 0 44.85 27.15 61.58 78.96 29.66 78.96 17.35 27.15 49.27 0 32.54"/>
    <polygon shape-rendering="geometricPrecision" class="cls-3" points="27.15 91.25 0 74.52 0 91.87 27.15 108.6 78.96 76.68 78.96 59.33 27.15 91.25"/>
    <polygon shape-rendering="geometricPrecision" class="cls-4" points="0 44.85 0 62.2 27.15 78.93 78.96 47.02 78.96 29.66 27.15 61.58 0 44.85"/>
    <polygon shape-rendering="geometricPrecision" class="cls-2" points="27.15 49.27 78.96 17.35 78.96 0 27.15 31.92 0 15.19 0 32.54 27.15 49.27"/>
  </g>
  <g>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M179.5,65.51v9.78h-39.59V12.5h39.59v9.78h-27.93v16.32h24.93v9.6h-24.93v17.31h27.93Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M189.22,12.5h11.21v62.79h-11.21V12.5Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M254.77,54.93h-34.37c.9,7.01,5.49,11.48,13.83,11.48,5.38,0,9.6-1.7,13.01-5.2l6.01,6.91c-4.3,5.11-11.03,7.8-19.28,7.8-15.51,0-24.51-9.73-24.51-24.57s8.99-24.49,22.8-24.49,22.68,9.78,22.68,24.75c0,.9-.09,2.24-.18,3.32ZM220.31,47.48h23.96c-.9-6.91-4.66-11.66-12.01-11.66-6.7,0-10.88,4.66-11.95,11.66Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M261.85,51.34c0-14.35,9.09-24.49,23.67-24.49,9.87,0,16.64,5.03,19.25,11.46l-8.68,4.68c-2.87-4.57-5.9-6.64-10.66-6.64-6.96,0-12.28,5.74-12.28,14.98s5.06,14.98,12.28,14.98c4.75,0,8.78-1.64,11.65-6.21l8.89,4.59c-3.68,7.18-11.12,11.21-20.45,11.21-15.16,0-23.67-10.22-23.67-24.57Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M350.03,47.93V12.5h11.66v34.98c0,11.83,4.28,18.09,13.4,18.09,8.62,0,13.47-6.06,13.47-18.09V12.5h11.48v35.43c0,18.3-7.91,28.25-25.04,28.25s-24.97-9.96-24.97-28.25Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M339.17,64.76c-1.67,1.31-2.91,1.92-5.21,1.92-3.36,0-5.64-2.51-5.64-7.18v-22.78h11.42v-8.97h-11.42v-15.25h0l-11.21,6.95v8.3h-6.89v8.97h6.89v23.05c0,10.58,6.59,16.14,15.5,16.14,4.01,0,7.04-1.07,9.72-3.2-1.41-2.27-2.46-4.93-3.16-7.96Z"/>
    <path shape-rendering="geometricPrecision" class="cls-2" d="M420.65,88.75v-18.95c3.84,4.09,7.8,6.11,13.9,6.11,13.99,0,20.84-10.28,20.84-24.57s-6.85-24.49-20.84-24.49c-6.55,0-10.67,2.24-14.43,6.82v-6.28h-10.67v68.27l11.21-6.91ZM433.29,36.36c8.07,0,12.17,7.06,12.17,14.98,0,9.15-4.1,14.98-12.17,14.98s-12.82-5.83-12.82-14.98c0-8.17,4.75-14.98,12.82-14.98Z"/>
  </g></svg>
</template>

<style scoped>
.cls-1 {
        fill: none;
      }

      .cls-2 {
        fill: #470069;
      }

      .cls-3 {
        fill: #d196ff;
      }

      .cls-4 {
        fill: #a62bed;
      }
      </style>